<template>
  <ValidationObserver @submit="addAndUpdateTopicHandler">
    <UiModalContainer
      footer-classes="flex justify-end"
      :modal-show="modal"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        {{ heading }}
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="topic.chapter_template_id"
            title="Chapter"
            :options="chaptersList"
            label="title"
            class="flex-1"
            reduce="id"
            rules="required"
          />
          <UiInput
            v-model.trim="topic.title"
            type="text"
            name="Title"
            title="Title"
            label="Title"
            class="flex-1"
            placeholder="Title"
            rules="required"
          />
        </InputFieldWrapper>

        <InputFieldWrapper>
          <UiInput
            v-model="topic.description"
            type="text"
            name="Description"
            title="Description"
            label="Description"
            placeholder="Description"
          />
          <UiInput
            v-model="topic.number"
            type="text"
            name="TOPIC_N"
            title="TOPIC_N"
            label="TOPIC_N"
            placeholder="TOPIC_N"
            rules="required|numeric"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <UIButton class="mr-5" :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
        <UIButton button="primary" :disabled="isLoading">
          <template v-if="isLoading">
            <Loader />
          </template>
          <template v-else>
            {{ saveBtnTitle }}
          </template>
        </UIButton>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import FILTER_KEYS from '@src/constants/filter-constants.js'
import Loader from '@components/BaseComponent/Loader.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { mapActions } from 'vuex'
import { buildWhereQuery } from '@utils/filters/index'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  name: 'TopicModal',
  components: {
    ValidationObserver,
    UiModalContainer,
    UiInput,
    UiSingleSelect,
    Loader,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean, default: false },
    edit: { type: Object, default: () => {} },
    currentSyllabusTemplateId: {
      type: String,
      default: '',
    },
  },
  emits: ['toggle'],
  data() {
    return {
      dashboard: 'dashboard',
      isLoading: false,
      chaptersList: [],
      topic: {
        title: '',
        description: '',
        number: null,
        chapter_template_id: '',
      },
    }
  },
  computed: {
    currentTemplateId() {
      return buildWhereQuery(
        FILTER_KEYS.EQUAL,
        'subject_template_id',
        this.currentSyllabusTemplateId,
      )
    },
    saveBtnTitle() {
      return this.edit ? 'Update' : 'Create'
    },
    heading() {
      return this.edit ? 'Edit Topic' : 'Add Topic'
    },
  },
  created() {
    if (this.edit) this.setTopicData()
    this.fetchChaptersData()
  },
  methods: {
    async fetchChaptersData() {
      let payload = {
        $where: {
          ...this.currentTemplateId,
        },
      }
      const { meta, records } = await this.getSyllabusTemplateChapterList(payload)
      this.chaptersList = records
    },

    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    addAndUpdateTopicHandler() {
      this.isLoading = true
      const data = {
        topic: this.topic,
      }
      if (this.edit) data.id = this.edit?.id
      this.createAndUpdateSyllabusTemplateTopic(data)
        .then((res) => {
          this.$store.commit(
            'toast/NEW',
            { message: `Successfully  ${this.edit ? 'Update' : 'Created'}`, type: 'success' },
            { root: true },
          )
          this.$emit('toggle', this.edit ? 'update' : 'fetch')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setTopicData() {
      this.topic.title = this.edit?.title
      this.topic.description = this.edit?.description
      this.topic.number = this.edit?.number
      this.topic.chapter_template_id = this.edit?.chapter_template?.id
    },
    ...mapActions('syllabusmanager', [
      'createAndUpdateSyllabusTemplateTopic',
      'getSyllabusTemplateChapterList',
    ]),
  },
}
</script>

<template>
  <ValidationObserver @submit="addAndUpdateLessonHandler">
    <UiModalContainer
      footer-classes="flex justify-end"
      :modal-show="modal"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        {{ heading }}
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="lesson.type"
            title="Lesson Type"
            :options="types"
            label="title"
            class="flex-1"
            rules="required"
          />
          <UiSingleSelect
            v-model="chapterId"
            title="Chapter"
            :options="chaptersList"
            label="title"
            class="flex-1"
            reduce="id"
            rules="required"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="lesson.topic_template_id"
            title="TOPIC"
            :options="topicList"
            label="title"
            class="flex-1"
            reduce="id"
            rules="required"
          />

          <UiInput
            v-model.trim="lesson.title"
            type="text"
            class="flex-1"
            name="Title"
            title="Title"
            label="Title"
            placeholder="Title"
            rules="required"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiInput
            v-model="lesson.description"
            type="text"
            name="Description"
            class="flex-1"
            title="Description"
            label="Description"
            placeholder="Description"
          />

          <UiInput
            v-model="lesson.lesson_number"
            type="text"
            name="Lesson Number"
            title="Lesson Number"
            class="flex-1"
            label="Lesson Number"
            placeholder="Lesson Number"
            rules="required|numeric"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <UIButton class="mr-5" :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
        <UIButton button="primary" :disabled="isLoading">
          <template v-if="isLoading">
            <Loader />
          </template>
          <template v-else>
            {{ saveBtnTitle }}
          </template>
        </UIButton>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import FILTER_KEYS from '@src/constants/filter-constants.js'
import Loader from '@components/BaseComponent/Loader.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { mapActions } from 'vuex'
import { buildWhereQuery } from '@utils/filters/index'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  name: 'TopicModal',
  components: {
    ValidationObserver,
    UiModalContainer,
    UiInput,
    UiSingleSelect,
    Loader,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean, default: false },
    edit: { type: Object, default: () => {} },
    currentSyllabusTemplateId: {
      type: String,
      default: '',
    },
  },
  emits: ['toggle'],
  data() {
    return {
      dashboard: 'dashboard',
      isLoading: false,
      chapterId: '',
      topicList: [],
      chaptersList: [],
      types: ['Theory', 'Lab', 'Assignment'],
      lesson: {
        title: '',
        description: '',
        lesson_number: null,
        topic_template_id: '',
        type: '',
      },
    }
  },
  computed: {
    currentTemplateId() {
      return buildWhereQuery(
        FILTER_KEYS.EQUAL,
        'subject_template_id',
        this.currentSyllabusTemplateId,
      )
    },
    saveBtnTitle() {
      return this.edit ? 'Update' : 'Create'
    },
    heading() {
      return this.edit ? 'Edit Lesson' : 'Add Lesson'
    },
  },
  watch: {
    chapterId: {
      handler(id) {
        this.lesson.topic_template_id = ''
        this.topicList = []
        if (id)
          this.fetchTopicsData(id).then(() => {
            if (this.edit) this.lesson.topic_template_id = this.edit?.topic_template?.id
          })
      },
    },
  },
  created() {
    if (this.edit) this.setTopicData()
    this.fetchChaptersData()
  },
  methods: {
    async fetchTopicsData(id) {
      let payload = {
        $where: {
          ...this.currentTemplateId,
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'chapter_template_id', id),
        },
      }
      const { meta, records } = await this.getSyllabusTemplateTopicList(payload)
      this.topicList = records
    },
    async fetchChaptersData() {
      let payload = {
        $where: {
          ...this.currentTemplateId,
        },
      }
      const { meta, records } = await this.getSyllabusTemplateChapterList(payload)
      this.chaptersList = records
    },

    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    addAndUpdateLessonHandler() {
      this.isLoading = true
      const data = {
        lesson: this.lesson,
      }
      if (this.edit) data.id = this.edit?.id
      this.createAndUpdateSyllabusTemplateLesson(data)
        .then((res) => {
          this.$store.commit(
            'toast/NEW',
            { message: `Successfully  ${this.edit ? 'Update' : 'Created'}`, type: 'success' },
            { root: true },
          )
          this.$emit('toggle', true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setTopicData() {
      this.lesson.title = this.edit?.title
      this.lesson.type = this.edit?.type
      this.lesson.description = this.edit?.description
      this.lesson.lesson_number = this.edit?.lesson_number
      this.chapterId = this.edit?.topic_template?.chapter_template?.id
    },
    ...mapActions('syllabusmanager', [
      'createAndUpdateSyllabusTemplateLesson',
      'getSyllabusTemplateTopicList',
      'getSyllabusTemplateChapterList',
    ]),
  },
}
</script>

<template>
  <ValidationObserver @submit="addNewChapter">
    <UiModalContainer
      footer-classes="flex justify-end"
      :modal-show="modal"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        {{ heading }}
      </template>
      <template v-slot>
        <div>
          <InputFieldWrapper>
            <UiInput
              v-model.trim="chapter.title"
              type="text"
              name="Chapter Title"
              title="Chapter Title"
              label="Chapter Title"
              placeholder="Chapter Title"
              rules="required"
            />
            <UiInput
              v-model="chapter.description"
              type="text"
              name="Description"
              title="Description"
              label="Description"
              placeholder="Description"
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <UiInput
              v-model="chapter.number"
              type="text"
              name="Chapter Number"
              title="Chapter Number"
              label="Chapter Number"
              placeholder="Chapter Number"
              rules="required|numeric"
            />
          </InputFieldWrapper>
        </div>
      </template>
      <template v-slot:footer>
        <UIButton
          v-i18n="dashboard"
          class="mr-5"
          :disabled="isLoading"
          @click="handleClick('close')"
        >
          Cancel
        </UIButton>
        <UIButton button="primary" :disabled="isLoading">
          <template v-if="isLoading">
            <Loader />
          </template>
          <template v-else>
            {{ saveBtnTitle }}
          </template>
        </UIButton>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import Loader from '@components/BaseComponent/Loader.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import { mapActions } from 'vuex'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  name: 'ChapterModal',
  components: {
    ValidationObserver,
    UiModalContainer,
    UiInput,
    Loader,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean, default: false },
    edit: { type: Object, default: () => {} },
    currentSyllabusTemplateId: {
      type: String,
      default: '',
    },
  },
  emits: ['toggle'],
  data() {
    return {
      dashboard: 'dashboard',
      isLoading: false,
      chapter: {
        title: '',
        description: '',
        number: null,
        subject_template_id: this.currentSyllabusTemplateId,
      },
    }
  },
  computed: {
    saveBtnTitle() {
      return this.edit ? 'Update' : 'Create'
    },
    heading() {
      return this.edit ? 'Edit Chapter' : 'Add Chapter'
    },
  },

  created() {
    if (this.edit) this.setChapterData()
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    addNewChapter() {
      this.isLoading = true
      const data = {
        chapter: this.chapter,
      }
      if (this.edit) data.id = this.edit?.id
      this.createAndUpdateSyllabusTemplateChapter(data)
        .then((res) => {
          this.$store.commit(
            'toast/NEW',
            { message: `Successfully  ${this.edit ? 'Update' : 'Created'}`, type: 'success' },
            { root: true },
          )
          this.$emit('toggle', this.edit ? 'update' : 'fetch')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setChapterData() {
      this.chapter.title = this.edit?.title
      this.chapter.description = this.edit?.description
      this.chapter.number = this.edit?.number
    },

    ...mapActions('syllabusmanager', ['createAndUpdateSyllabusTemplateChapter']),
  },
}
</script>

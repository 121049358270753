<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="SYLLABUS_LIB_DETAIL"
      tooltip-title="SYLLABUS_LIB_DETAIL"
      :display-breadcrumb="true"
    ></TitleWrapper>

    <template v-if="isDataLoading">
      <Loader class="mt-20 mb-40" :content="true" />
    </template>
    <div v-else class="py-3">
      <DetailsCard class="my-5" :details="templateDetails" custom-classes="flex-col" />
      <!-- Chapters -->
      <div class="flex justify-between items-center">
        <Heading heading="Chapters" />
        <UIButton class="px-4" @click="toggleChapterModal">Add Chapters</UIButton>
      </div>
      <template v-if="isChaptersDataLoading">
        <Loader class="mt-20 mb-40" :content="true" />
      </template>
      <div v-else-if="!isChaptersDataLoading && isEmpty(chaptersList)">
        <NoRecordFound />
      </div>
      <TableWrapper v-else id="chapterTable">
        <THead>
          <TRHead>
            <TH v-for="head in chaptersHeaders" :key="head">
              {{ head }}
            </TH>
          </TRHead>
        </THead>
        <TBody>
          <TRBody v-for="(chapter, index) in chaptersList" :key="`index-${index}`">
            <TD>
              {{ chapter.number || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              {{ chapter.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              {{ chapter.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              <TableAction
                :idx="index"
                :current-user="chapter"
                :action-list="actionList"
                @action="tableActionHandlerForChapters"
              />
            </TD>
          </TRBody>
        </TBody>
      </TableWrapper>
      <Pagination
        v-if="showPaginationForChapters"
        v-show="!isChaptersDataLoading"
        :key="`chapter-${reRenderChapterPagination}`"
        :record-limit="GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
        :max-range="paginationCounts(chaptersCount, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)"
        @filterRecord="fetchChaptersData"
      />
      <!-- Topics -->
      <div class="flex justify-between items-center mt-5">
        <Heading heading="Topics" />
        <UIButton class="px-6" @click="toggleTopicModal">Add Topics</UIButton>
      </div>
      <template v-if="isTopicsDataLoading">
        <Loader class="mt-20 mb-40" :content="true" />
      </template>
      <div v-else-if="!isTopicsDataLoading && isEmpty(topicsList)">
        <NoRecordFound />
      </div>
      <TableWrapper v-else id="topicTable">
        <THead>
          <TRHead>
            <TH v-for="head in topicsHeaders" :key="head">
              {{ head }}
            </TH>
          </TRHead>
        </THead>
        <TBody>
          <TRBody v-for="(topic, index) in topicsList" :key="`index-${index}`">
            <TD>
              {{ topic.number || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              {{ topic.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              {{ topic.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              {{ topic.chapter_template.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              <TableAction
                :idx="index"
                :current-user="topic"
                :action-list="actionList"
                @action="tableActionHandlerForTopics"
              />
            </TD>
          </TRBody>
        </TBody>
      </TableWrapper>
      <Pagination
        v-if="showPaginationForTopics"
        v-show="!isTopicsDataLoading"
        :key="`topic-${reRenderTopicPagination}`"
        :record-limit="GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
        :max-range="paginationCounts(topicsCount, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)"
        @filterRecord="fetchTopicsData"
      />
      <!-- Lesson -->
      <div class="flex justify-between items-center">
        <Heading heading="Lessons" />
        <UIButton class="px-5" @click="toggleLessonModal">Add Lesson</UIButton>
      </div>
      <template v-if="isLessonDataLoading">
        <Loader class="mt-20 mb-40" :content="true" />
      </template>
      <div v-else-if="!isLessonDataLoading && isEmpty(lessonList)">
        <NoRecordFound />
      </div>
      <TableWrapper v-else id="lessonTable">
        <THead>
          <TRHead>
            <TH v-for="head in lessonHeaders" :key="head">
              {{ head }}
            </TH>
          </TRHead>
        </THead>
        <TBody>
          <TRBody v-for="(lesson, index) in lessonList" :key="`index-${index}`">
            <TD>
              {{ lesson.lesson_number || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              {{ lesson.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              {{ lesson.type || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              {{ lesson.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              {{
                (lesson.topic_template.chapter_template &&
                  lesson.topic_template.chapter_template.title) ||
                GENERAL_CONSTANTS.NOT_APPLICABLE
              }}
            </TD>
            <TD>
              {{ lesson.topic_template.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>

            <TD>
              <TableAction
                :idx="index"
                :current-user="lesson"
                :action-list="actionList"
                @action="tableActionHandlerForLesson"
              />
            </TD>
          </TRBody>
        </TBody>
      </TableWrapper>
      <Pagination
        v-if="showPaginationForLesson"
        v-show="!isLessonDataLoading"
        :key="`lesson${reRenderLessonPagination}`"
        :record-limit="GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
        :max-range="paginationCounts(lessonCount, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)"
        @filterRecord="fetchLessonData"
      />
    </div>
    <!-- Modals Here -->

    <AddEditChapterModal
      v-if="showChapterModal"
      :modal="showChapterModal"
      :current-syllabus-template-id="currentSyllabusTemplateId"
      :edit="currentSelectedChapter"
      @toggle="toggleChapterModal"
    />
    <AddEditTopicModal
      v-if="showTopicModal"
      :modal="showTopicModal"
      :current-syllabus-template-id="currentSyllabusTemplateId"
      :edit="currentSelectedTopic"
      @toggle="toggleTopicModal"
    />
    <AddEditLessonModal
      v-if="showLessonModal"
      :modal="showLessonModal"
      :current-syllabus-template-id="currentSyllabusTemplateId"
      :edit="currentSelectedLesson"
      @toggle="toggleLessonModal"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import DetailsCard from '@src/components/UiElements/DetailCard.vue'
import Heading from '@components/Heading.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import TableAction from '@components/TableAction.vue'
import { mapState, mapActions } from 'vuex'
import AddEditChapterModal from '@views/syllabus/template/modals/AddAndEditTemplateChapterModal.vue'
import AddEditTopicModal from '@views/syllabus/template/modals/AddAndEditTemplateTopicModal.vue'
import AddEditLessonModal from '@views/syllabus/template/modals/AddAndEditTemplateLessonModal.vue'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import Loader from '@components/BaseComponent/Loader.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import isEmpty from 'lodash/isEmpty'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import { formatDate } from '@utils/moment.util'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    TitleWrapper,
    DetailsCard,
    Heading,
    UIButton,
    TableAction,
    AddEditChapterModal,
    AddEditTopicModal,
    Loader,
    NoRecordFound,
    Pagination,
    AddEditLessonModal,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [scrollMixin, GeneralMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      isEmpty,
      currentSyllabusTemplateId: '',
      actionList: [{ name: 'Edit' }],
      isDataLoading: false,
      // Chapters related state
      chaptersHeaders: ['Chapter No', 'Title', 'Description', 'Actions'],
      chaptersList: [],
      chaptersCount: 0,
      currentSelectedChapter: null,
      showChapterModal: false,
      isChaptersDataLoading: false,
      showPaginationForChapters: false,
      reRenderChapterPagination: 0,
      // Topics related state
      topicsHeaders: ['Topic No', 'Title', 'Description', 'Chapter Template', 'Actions'],
      topicsList: [],
      topicsCount: 0,
      currentSelectedTopic: null,
      showTopicModal: false,
      isTopicsDataLoading: false,
      showPaginationForTopics: false,
      reRenderTopicPagination: 0,
      // Lesson related state
      lessonHeaders: [
        'Lesson No',
        'Title',
        'Lesson Type',
        'Description',
        'Chapter Template',
        'Topic Template',
        'Actions',
      ],
      lessonList: [],
      lessonCount: 0,
      currentSelectedLesson: null,
      showLessonModal: false,
      isLessonDataLoading: false,
      showPaginationForLesson: false,
      reRenderLessonPagination: 0,

      // Template Details
      templateDetails: {},
    }
  },
  computed: {
    ...mapState({
      currentCampusScope: (state) => state?.layout?.currentCampusScope,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.$router?.push({ name: 'subject-syllabus-template' })
      },
    },
  },
  created() {
    this.currentSyllabusTemplateId = this.$route.params.id
  },
  mounted() {
    this.fetchRecords()
  },
  methods: {
    fetchRecords() {
      this.isDataLoading = true
      let range = paginationRangeHandler(_, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)
      let payload = {
        ...range,
        $where: {
          ...buildWhereQuery(
            FILTER_KEYS.EQUAL,
            'subject_template_id',
            this.currentSyllabusTemplateId,
          ),
        },
      }
      const templateDetails = this.getSyllabusTemplateDetail(this.currentSyllabusTemplateId)
      const chapters = this.getSyllabusTemplateChapterList(payload)
      const topics = this.getSyllabusTemplateTopicList(payload)
      const lesson = this.getSyllabusTemplateLessonList(payload)

      Promise.all([chapters, topics, lesson, templateDetails]).then((values) => {
        const { meta: chaptersMeta, records: chaptersRecords } = values[0]
        const { meta: topicsMeta, records: topicsRecords } = values[1]
        const { meta: lessonMeta, records: lessonRecords } = values[2]
        this.templateDetails = this.transformDetails(values[3])

        this.chaptersList = chaptersRecords
        this.chaptersCount = chaptersMeta.total_records
        this.showPaginationForChapters = this.chaptersCount > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT

        this.topicsList = topicsRecords
        this.topicsCount = topicsMeta.total_records
        this.showPaginationForTopics = this.topicsCount > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT

        this.lessonList = lessonRecords
        this.lessonCount = lessonMeta.total_records
        this.showPaginationForLesson = this.lessonCount > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT

        this.isDataLoading = false
      })
    },

    transformDetails(details) {
      return {
        title: details.title,
        syllabusProvider: details.syllabus_provider.title,
        importCount: details.import_count,
        updatedAt: formatDate(details.updated_at),
        edition: details.edition,
      }
    },

    async toggleChapterModal(payload) {
      this.showChapterModal = !this.showChapterModal
      if (!this.showChapterModal) this.currentSelectedChapter = null

      if (payload === 'fetch') this.fetchChaptersData()
      else if (payload === 'update') {
        this.isDataLoading = true
        await this.fetchChaptersData()
        await this.fetchTopicsData()
        await this.fetchLessonData()
        this.isDataLoading = false
      }
    },

    async fetchChaptersData(range) {
      this.isChaptersDataLoading = true
      let paginationRange = paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)
      let payload = {
        ...paginationRange,
        $where: {
          ...buildWhereQuery(
            FILTER_KEYS.EQUAL,
            'subject_template_id',
            this.currentSyllabusTemplateId,
          ),
        },
      }

      const { meta, records } = await this.getSyllabusTemplateChapterList(payload)

      this.chaptersList = records
      this.chaptersCount = meta.total_records
      this.showPaginationForChapters = this.chaptersCount > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT
      if (!paginationRange.skip) this.reRenderChapterPagination++
      this.isChaptersDataLoading = false
    },

    deleteTemplateChapter(id) {
      this.deleteSyllabusTemplateChapter(id).then(() => {
        this.fetchChaptersData()
        this.currentSelectedChapter = null
      })
    },

    async toggleTopicModal(payload) {
      this.showTopicModal = !this.showTopicModal
      if (!this.showTopicModal) this.currentSelectedTopic = null

      if (payload === 'fetch') this.fetchTopicsData()
      else if (payload === 'update') {
        this.isDataLoading = true
        await this.fetchTopicsData()
        await this.fetchLessonData()
        this.isDataLoading = false
      }
    },

    async fetchTopicsData(range) {
      this.isTopicsDataLoading = true
      let paginationRange = paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)
      let payload = {
        ...paginationRange,
        $where: {
          ...buildWhereQuery(
            FILTER_KEYS.EQUAL,
            'subject_template_id',
            this.currentSyllabusTemplateId,
          ),
        },
      }
      const { meta, records } = await this.getSyllabusTemplateTopicList(payload)
      this.topicsList = records
      this.topicsCount = meta.total_records
      this.showPaginationForTopics = this.topicsCount > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT
      if (!paginationRange.skip) this.reRenderTopicPagination++
      this.isTopicsDataLoading = false
    },

    deleteTemplateTopic(id) {
      this.deleteSyllabusTemplateTopic(id).then(() => {
        this.fetchTopicsData()
        this.currentSelectedTopic = null
      })
    },

    toggleLessonModal(payload) {
      this.showLessonModal = !this.showLessonModal
      if (!this.showLessonModal) this.currentSelectedLesson = null
      if (payload) this.fetchLessonData()
    },

    async fetchLessonData(range) {
      this.isLessonDataLoading = true
      let paginationRange = paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)
      let payload = {
        ...paginationRange,
        $where: {
          ...buildWhereQuery(
            FILTER_KEYS.EQUAL,
            'subject_template_id',
            this.currentSyllabusTemplateId,
          ),
        },
      }
      const { meta, records } = await this.getSyllabusTemplateLessonList(payload)
      this.lessonList = records
      this.lessonCount = meta.total_records
      this.showPaginationForLesson = this.lessonCount > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT
      if (!paginationRange.skip) this.reRenderLessonPagination++
      this.isLessonDataLoading = false
    },

    deleteTemplateLesson(id) {
      this.deleteSyllabusTemplateLesson(id).then(() => {
        this.fetchLessonData()
        this.currentSelectedLesson = null
      })
    },

    tableActionHandlerForChapters(action, currentType, idx) {
      this.currentSelectedChapter = currentType
      switch (action) {
        case 'Edit':
          this.toggleChapterModal()
          break
        case 'Delete':
          this.deleteTemplateChapter(currentType.id)
          break
      }
    },

    tableActionHandlerForTopics(action, currentType, idx) {
      this.currentSelectedTopic = currentType
      switch (action) {
        case 'Edit':
          this.toggleTopicModal()
          break
        case 'Delete':
          this.deleteTemplateTopic(currentType.id)
          break
      }
    },

    tableActionHandlerForLesson(action, currentType, idx) {
      this.currentSelectedLesson = currentType
      switch (action) {
        case 'Edit':
          this.toggleLessonModal()
          break
        case 'Delete':
          this.deleteTemplateLesson(currentType.id)
          break
      }
    },

    ...mapActions('syllabusmanager', [
      'getSyllabusTemplateChapterList',
      'getSyllabusTemplateLessonList',
      'getSyllabusTemplateTopicList',
      'getSyllabusTemplateDetail',
      'deleteSyllabusTemplateChapter',
      'deleteSyllabusTemplateTopic',
      'deleteSyllabusTemplateLesson',
    ]),
  },
}
</script>
